import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`24/10/19`}<br parentName="p"></br>{`
`}{`I am startled by the noise of the sharp German words thundering into my ears. It’s time to get up and march yet again… For days, we walk through these fields and we never seem to get any closer. The hunger burns through my stomach like I cannot imagine. I already had to live through one famine at home, will I need to do it again?`}</p>
    <p>{`Home.`}</p>
    <p>{`I try to throw the thought out of my head. “It only slows me down,” I tell myself. I try to forget about my parents and the siblings I have left at home.`}<br parentName="p"></br>{`
`}{`Who knows if they are still there?`}</p>
    <p>{`Every so often, some of the other prisoners run into the fields surrounding us and grab some corn. Our guards are considered the ‘good’ ones since they don’t shoot us on sight of stepping out of line.`}</p>
    <p>{`I trudge along, waiting for another day to pass.`}</p>
    <p>{`Out of nowhere, we approach a train station. Finally, we will no longer need to walk. The hardest part is now over. Or, so I thought.`}</p>
    <p>{`The old buildings are run down and the walls are peeling. The large train engine drags dozens of red train cars behind it. The officers force us into one. “How the hell will we fit in here?” I heard someone yell. It’s what we are all wondering.`}<br parentName="p"></br>{`
`}{`Somehow they wedge us in. Packed like sardines, I try to make my way to the sides of the train car so I have something to lean on.`}</p>
    <hr></hr>
    <p>{`The trip is worse than hell. I can sit down occasionally and even get some sleep. But this is rare. I laugh at my naivety - thinking I knew real hunger before.`}</p>
    <h2 {...{
      "id": "after-the-first-few-days-i-fall-into-an-absent-state-i-no-longer-think-of-my-hunger-i-no-longer-think-of-my-pain-in-fact-i-no-longer-think-of-anything-at-all",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#after-the-first-few-days-i-fall-into-an-absent-state-i-no-longer-think-of-my-hunger-i-no-longer-think-of-my-pain-in-fact-i-no-longer-think-of-anything-at-all",
        "aria-label": "after the first few days i fall into an absent state i no longer think of my hunger i no longer think of my pain in fact i no longer think of anything at all permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`After the first few days, I fall into an absent state. I no longer think of my hunger, I no longer think of my pain. In fact, I no longer think of anything at all.`}</h2>
    <p>{`When they open the doors, I look up at the sky. I never realized how starved one could feel for something so simple. But the sky is black, and for some reason, it seems it would never have color again.`}</p>
    <p>{`They separate us into lines. All of us ‘Soviets’ have to strip naked. They pour disinfecting fluid over us. It smells and feels terrible. They treat us like we’re no more than an infection that needs to be treated. And why?`}</p>
    <p>{`We hear the same stories about them that, I’m sure, they are told about us. That they’re monsters, that they threaten the existence of our people and need to be destroyed.`}</p>
    <p>{`Is it really so easy to believe these stories? When I was home in Khoruzhivka, I never shot at a German soldier. I understood that, just as I was forced to fight for this Soviet monstrosity of a state, my brothers were forced to fight for the German monster.`}</p>
    <p>{`My officers even dared to tell me: “the last bullet in your gun is for yourself”. Did these people really think`}<br parentName="p"></br>{`
`}{`I would die for this artificial, brutal state? After what they did to me? To my family?`}<br parentName="p"></br>{`
`}{`I’d rather point my gun in the opposite direction.`}</p>
    <p>{`It’s funny. I remember how my father told me the history of our people. Constantly being torn apart, gutted and stripped of solidarity.`}<br parentName="p"></br>{`
`}{`Has anything changed?`}</p>
    <p>{`They put us Soviet combatant prisoners in a line. They march us away and force us into a dark room, one at a time. We are told to take off our shirts. They use a small machine and start tattooing numbers onto us. They label us like we’re cattle to keep track of on a large farm. They mark us like we are now their property.`}</p>
    <p>{`They hand us back our shirts with a red triangle on the sleeve.`}</p>
    <p>{`We are escorted to a different section of the camp. We walk through a different set of gates. More barbed wire, more guard towers. Is it so important to keep us here? Are we really their enemies? I wonder — who are they? Who are we?`}</p>
    <p>{`When we get to our barracks, I am shocked to see only hay on the floor, no beds. We really are animals in a barn.`}</p>
    <p>{`It is already night, so I try to find room on the floor.`}</p>
    <p>{`I look at the number they tattooed on me. I memorize it. It is now more than a number. It is who I am. The thick black paint embedded in my flesh represents my life, my story, and my only purpose.`}<br parentName="p"></br>{`
`}{`117654 is now synonymous with Andriy.`}<br parentName="p"></br>{`
`}{`I look at the boy, just a bit younger than I, laying next to me, tears rolling down his face. His number is two numbers greater than mine.`}</p>
    <hr></hr>
    <p>{`The first morning, before dawn, they hustle us out of the barracks. We are given tools and told we would now build a road. The nearby village has already been evacuated. All the buildings are being demolished. Looking around, I see the personal belongings of the former inhabitants of these homes lying strewn all over the ground. Everything these people did not have time to take is now being ripped apart. Other prisoners are building new buildings. Apparently, they are to be connected to our camp. I am so confused. Who are they? Who are we?`}</p>
    <p>{`Even though all of us soldiers are in a separate building from the regular prisoners, sometimes, when we are working construction, I get to speak to some of the men who know Russian, or even sometimes Ukrainian. Luckily, I also know English, so I can speak to more of the prisoners and this keeps me from being totally isolated and broken, like so many people here.`}</p>
    <p>{`They tell me I’m lucky that I am allowed to work outside. They say I’m lucky that I can take any food, plants I find outside. They say I’m lucky I haven’t just been shot like many of the other soldiers.`}</p>
    <p>{`Is there anybody here who is actually lucky?`}</p>
    <hr></hr>
    <p>{`I dread working each day. The work couldn’t be harder. The food rations couldn’t be smaller. I agree with the guards. Yes, the communists should be destroyed. I hate them just as much as you!`}</p>
    <p>{`How can someone hate me so much for something that isn’t even true?`}</p>
    <p>{`Still, I have to go out and die a little more each day. I am so close to just throwing up my arms and giving up. But no, my family line didn’t come all this way, fight all of their battles for hundreds of years, dream of freedom, demand independence, survive all of these genocides, just to have me die now.`}</p>
    <p>{`A single thought occupies my mind. I must fight. I must fight that feeling that tells me to give up. I must never stop. I must never stop until I achieve the one thing in my life that matters to me. No, it is not to hold on to life. It is not to see my family again, not to return home. The one thing that matters — I must have a son. I must never stop until I can show him how good this world can be. I must never stop until I teach him to love life.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      